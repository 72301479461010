import React from "react";
import { useNavigate } from "react-router";
import Button, { ButtonType } from "components/Button";
import { AccessStatus, DeviceId } from "modules/constants";

import DeviceHeader from "components/DeviceHeader/DeviceHeader";

import classes from "./CollectingDevice.module.scss";
import { mapIdToDevice } from "screens/ConnectDevice";
import { RoutesPaths } from "modules/routes";

const CollectingDevice = ({ device }: { device: DeviceId }) => {
  const navigate = useNavigate();

  return (
    <>
      <DeviceHeader device={device} status={AccessStatus.collecting} />

      <div className={classes["collecting-container"]}>
        <div className={classes["collecting-content"]}>
          Your {mapIdToDevice[device].title} app is connected successfully but
          it will still take some time to upload all your activity data.
        </div>
        <div className={classes["collecting-content"]}>
          In the meantime, you can continue to the next step of the process and
          complete your journey.
        </div>
        <div className={classes["collecting-content"]}>
          If you'd like to connect another data source, select 'Try another
          device' at the bottom of this page.
        </div>
      </div>
      <div className={classes["buttons-container"]}>
        <Button
          icon="arrowRight"
          type={ButtonType.rounded}
          onClick={() => navigate(RoutesPaths.profile)}
        >
          Next
        </Button>

        <Button
          type={ButtonType.underlined}
          onClick={() => navigate(RoutesPaths.chooseDevices)}
        >
          Try another device
        </Button>
      </div>
    </>
  );
};

export default CollectingDevice;
