import axios from "axios";
import { config } from "./constants";

export enum ProviderId {
  apple = "apple",
  garmin = "garmin",
  google = "google",
  fitbit = "fitbit",
  underarmour = "underarmour"
}

export const Endpoints = {
  sendSms: "/sms/send",
  feedback: "/feedback",
  profile: "/user/profile",
  deeplink: "/user/deeplink",
  authProvider: "/user/provider/",
  collectionsStatus: "/collection/status",
  trackThankYou: "/track/thank-you-screen",
  trackWellDone: "/track/waiting-data-screen",
};

export const apiCall = axios.create({
  baseURL: config.api
});

export default apiCall;
