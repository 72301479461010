import React from "react";

import { handlePreventDefault } from "modules/utils";
import PrivacyPolicyContent from "screens/PrivacyPolicy/PrivacyPolicyContent";
import "./styles.scss";

interface Props {
  onClose?: () => void;
}

const DataPrivacy: React.FunctionComponent<Props> = ({ onClose }) => (
  <div className="data-privacy" onClick={onClose}>
    <div className="data-privacy-container" onClick={handlePreventDefault}>
      <div className="data-privacy-content">
        <div className="controls">
          <button className="button close" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="title">
          <span>Privacy Policy</span>
        </div>

        <div className="content">
          <PrivacyPolicyContent />
        </div>
      </div>
    </div>
  </div>
);

export default DataPrivacy;
