import React from "react";
import Button, { ButtonType } from "components/Button";
import { AccessStatus, DeviceId } from "modules/constants";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "modules/routes";

import DeviceHeader from "../DeviceHeader/DeviceHeader";
import { mapIdToDevice } from "screens/ConnectDevice";
import classes from "./ConnectedDevice.module.scss";

const ConnectedDevice = ({ device }: { device: DeviceId }) => {
  const navigate = useNavigate();

  return (
    <>
      <DeviceHeader device={device} status={AccessStatus.connected} />
      <div className={classes["small-content"]}>
        Your {mapIdToDevice[device].title} app is connected and we're received
        your activity data.
      </div>

      <Button
        icon="arrowRight"
        type={ButtonType.rounded}
        onClick={() => navigate(RoutesPaths.chooseDevices)}
      >
        Next
      </Button>
    </>
  );
};

export default ConnectedDevice;
