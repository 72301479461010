import React from "react";
import {
  destroyUser,
  getInitialUser,
  getUserFromLink,
  getUserFromQuery,
} from "modules/context/contextUtils";
import { User } from "typing";

export const UserContext = React.createContext<{
  user: User;
  destroy: () => void;
  pending: boolean;
}>({
  user: { id: "", token: "" },
  destroy: () => {},
  pending: false,
});

const UserProvider: React.FC<{}> = ({ children }) => {
  const [user, setUser] = React.useState<User | null>();
  const [pending, setPending] = React.useState<boolean>();

  React.useEffect(() => {
    const prepareUser = async () => {
      setPending(true);

      const _user =
        getUserFromQuery() || (await getUserFromLink()) || getInitialUser();

      if (_user) {
        setUser(_user);
      }

      setPending(false);
    };

    prepareUser();
  }, []);

  const destroy = () => {
    setUser(null);
    destroyUser();
  };

  return (
    <UserContext.Provider value={{ user, destroy, pending }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = React.useContext(UserContext);

  return context;
}

export default UserProvider;
