import React, { useState } from "react";
// import { ReactQueryDevtools } from "react-query/devtools"; // Develop help tool to react-query

// import { hotjar } from "react-hotjar";

import branch from "branch-sdk";
import { QueryClient, QueryClientProvider } from "react-query";

import AlertProvider from "./modules/context/AlertProvider";

import UserProvider from "./modules/context/UserProvider";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { config } from "modules/constants";

import "./normalize.css";
import "./App.css";
import "./styles.scss";

import AppNavigator from "./AppNavigator";
import ContactUs from "components/Modals/ContactUs";

// hotjar.initialize(parseInt(config.hjid), parseInt(config.hjsv));
branch.init(config.branchKey);

Bugsnag.start({
  apiKey: config.bugsnagId,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const queryClient = new QueryClient();

const App: React.FunctionComponent<{}> = () => {
  const [showContactUs, toggleContactUs] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <AlertProvider>
          <UserProvider>
            <AppNavigator />
            {/* Develop help tool to react-query */}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}

            {showContactUs && (
              <ContactUs onClose={() => toggleContactUs(false)} />
            )}
          </UserProvider>
        </AlertProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
