import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import TermsAndConds from "components/Modals/TermsAndConds";
import { Policy, useProfileMutation } from "modules/utils/hooks";
import DataPrivacy from "components/Modals/DataPrivacy";
import CheckBox from "components/Checkbox";
import Button from "components/Button";

import {
  useAlert,
  DefaultError,
  DefaultErrorTitle
} from "modules/context/AlertProvider";
import Pending from "components/Pending";
import Layout from "components/Layout/Layout";
import { useUser } from "modules/context/UserProvider";
import { RoutesPaths } from "modules/routes";

import "./styles.scss";

const POLICIES = "@POLICIES";

const SignInScreen: React.FunctionComponent<{}> = () => {
  const { mutate } = useProfileMutation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { show } = useAlert();

  const [showTermsAndConds, toggleTermsAndConds] = useState(false);
  const [showDataPrivacy, toggleDataPrivacy] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);

  const [policies, setPolicy] = useState([]);

  const canSubmit = policies?.length === 2;
  const isChecked = (policy: Policy) => policies.includes(policy);

  useEffect(() => {
    if (user)
      setPolicy(
        JSON.parse(window.localStorage.getItem(POLICIES + user.id)) || []
      );
  }, [user]);

  const updatePolicy = (policy: Policy): void => {
    const checked = policies.includes(policy);

    const data = checked
      ? policies.filter((item) => item !== policy)
      : [...policies, policy];

    if (data.length === 2) setError(false);

    setPolicy(data);
  };

  const savePolicies = async (policies: string[]) => {
    window.localStorage.setItem(POLICIES + user.id, JSON.stringify(policies));

    try {
      setPending(true);

      await mutate({
        policies: [Policy.privacy, Policy.terms]
      });

      setPending(false);
      navigate(RoutesPaths.chooseDevices);
    } catch (error) {
      console.log(error);
      show(DefaultErrorTitle, <DefaultError />);
    }
  };

  const submit = async () => {
    if (canSubmit) {
      await savePolicies(policies);
    } else {
      setError(true);
    }
  };

  return (
    <Layout progressBarPercent={25}>
      <div className="sign-in">
        <div className="sign-in-header">Privacy Policy</div>

        {pending ? (
          <Pending />
        ) : (
          <>
            <div className="sign-in-subscription">
              You will need to agree to our data policy and the terms and
              conditions to activate your Qumata account:
            </div>
            <div className="checkbox-container">
              <CheckBox
                value="privacy"
                checked={isChecked(Policy.privacy)}
                onChange={() => updatePolicy(Policy.privacy)}
                error={error && !isChecked(Policy.privacy)}
                label={
                  <React.Fragment>
                    <span>
                      I agree to the Qumata{" "}
                      <span
                        className="link"
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          toggleDataPrivacy(true);
                        }}
                      >
                        Data Privacy
                      </span>
                    </span>
                  </React.Fragment>
                }
              />
            </div>
            <div className="checkbox-container">
              <CheckBox
                value="terms"
                checked={isChecked(Policy.terms)}
                onChange={() => updatePolicy(Policy.terms)}
                error={error && !isChecked(Policy.terms)}
                label={
                  <React.Fragment>
                    <span>
                      I agree to the Qumata{" "}
                      <span
                        className="link"
                        onClick={() => toggleTermsAndConds(true)}
                      >
                        Terms of Use
                      </span>
                    </span>
                  </React.Fragment>
                }
              />
            </div>
            {error && (
              <div className="error-message">
                You must agree to our Privacy Policy and Terms and Conditions to
                continue.
              </div>
            )}
            <div className="sign-in-buttons-wrapper">
              <Button icon="arrowRight" onClick={submit} type="rounded">
                Continue
              </Button>
            </div>
          </>
        )}

        {showDataPrivacy && (
          <DataPrivacy onClose={() => toggleDataPrivacy(false)} />
        )}
        {showTermsAndConds && (
          <TermsAndConds onClose={() => toggleTermsAndConds(false)} />
        )}
      </div>
    </Layout>
  );
};

export default SignInScreen;
