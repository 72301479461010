import qs from "qs";
import branch from "branch-sdk";
import Bugsnag from "@bugsnag/js";

import { User } from "typing";

const { localStorage, location: { search } } = window;
export const USER = "oc_user";

/** retrieves user from localStorage
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getInitialUser = (): User | null => {
  try {
    const userString = window.localStorage.getItem(USER);

    return JSON.parse(userString);
  } catch (error) {
    return null;
  }
};

/** retrieves user from query params
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getUserFromQuery = (): User | null => {
  if (!search) return;

  const query = search.replace("?", "");

  try {
    const queryString = atob(query);
    const { id, token } = qs.parse(queryString);

    if (id && token) {
      localStorage.setItem(USER, JSON.stringify({ id, token }));

      return { id: id.toString(), token: token.toString() };
    }

    return null;
  } catch (error) {
    Bugsnag.notify(error);
    return null;
  }
};

type ResultData = {
  data_parsed: {
    ["username"]?: string;
    ["token"]?: string;
    ["server"]?: string;
  };
};

/** Make promise with callback func
 *
 * @returns params if app open from deep link
 *
 */
const getBranchData = (): Promise<ResultData> =>
  new Promise((resolve, reject) => {
    const cb = (err: string, results: ResultData) =>
      err ? reject(err) : resolve(results);

    return branch.data(cb);
  });

/** Retrieves user from deep link params with branch io sdk
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getUserFromLink = async (): Promise<User | null> => {
  try {
    const { data_parsed } = await getBranchData();
    const username = data_parsed?.["username"];
    const token = data_parsed?.["token"];
    const server = data_parsed?.["server"]
      ? `https://${data_parsed?.["server"]}`
      : "";

    if (!username || !token) {
      return null;
    }

    localStorage.setItem(USER, JSON.stringify({ id: username, token, server }));

    return { id: username, token };
  } catch (error) {
    return null;
  }
};

export const destroyUser = () => {
  localStorage.removeItem(USER);
};
