import React from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { useProfileMutation } from "modules/utils/hooks";
import { conditions, conditionsValues } from "./helper";
import Layout from "components/Layout/Layout";
import CheckBox from "components/Checkbox";
import { RoutesPaths } from "modules/routes";
import Button from "components/Button";

import "./styles.scss";
import {
  useAlert,
  DefaultError,
  DefaultErrorTitle
} from "modules/context/AlertProvider";
import Bugsnag from "@bugsnag/js";

const Conditions: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted] = React.useState(false);
  const { mutate } = useProfileMutation();
  const { show, close } = useAlert();

  const submit = async (values) => {
    setSubmitted(true);

    try {
      const conditions: string[] = Object.keys(values)
        .filter((key) => values[key])
        .map((key) => conditions[key]);

      if (!conditions.some((i) => i)) {
        return show(
          "Select conditions!",
          <div className="error-wrapper">
            <div className="error-message-container">
              <p>
                Please select any conditions you have or switch off conditions
                button.
              </p>
            </div>
            <Button type="rounded" onClick={close}>
              Select
            </Button>
          </div>
        );
      }

      await mutate({ conditions });
      navigate(RoutesPaths.thankYou);
    } catch (error) {
      Bugsnag.notify(error);
      show(DefaultErrorTitle, <DefaultError />);
    }

    setSubmitted(false);
  };

  return (
    <Layout>
      <div className="profile">
        <React.Fragment>
          <div className="profile-header" style={{ marginBottom: 20 }}>
            Pre-Existing medical conditions
          </div>

          <div className="subHeader">
            Please select if you have been diagnosed with any of these medical
            conditions?
          </div>
        </React.Fragment>

        <Formik
          onSubmit={(values) => submit(values)}
          initialValues={{
            ...conditionsValues
          }}
        >
          {({
            values: { ...conditionsValues },
            handleChange,
            handleSubmit
          }) => {
            return (
              <React.Fragment>
                <div className="sections column">
                  <React.Fragment>
                    {Object.keys(conditions).map((key) => (
                      <div className="section-row" key={key}>
                        <CheckBox
                          value={conditionsValues[key]}
                          label={conditions[key]}
                          onChange={handleChange(key)}
                        />
                      </div>
                    ))}
                  </React.Fragment>
                </div>
                <div className="buttons-wrapper">
                  <Button
                    disabled={isSubmitted}
                    icon="arrowRight"
                    onClick={handleSubmit}
                    pending={isSubmitted}
                    type="rounded"
                  >
                    Continue
                  </Button>
                </div>
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default Conditions;
