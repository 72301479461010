import React from "react";

import { handlePreventDefault } from "modules/utils";

import "./styles.scss";

interface Props {
  onClose?: () => void;
}

const TermsAndConds: React.FunctionComponent<Props> = ({ onClose }) => (
  <div className="terms-and-conds" onClick={onClose}>
    <div className="terms-and-conds-container" onClick={handlePreventDefault}>
      <div className="terms-and-conds-content">
        <div className="controls">
          <button className="button close" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="title">
          <span>Terms of Use</span>
        </div>
        <div className="content">
          <div className="subheader">
            Qumata Score App and Website – End User Terms of Use
            <br />
            Version 1 – Last updated 20 January 2019
          </div>

          <a className="header-link" href="#introduction">
            Introduction
          </a>
          <a className="header-link" href="#definitions">
            Definitions
          </a>
          <a className="header-link" href="#about-the-score-app">
            About the Score App
          </a>
          <a className="header-link" href="#hh-services">
            Qumata Services
          </a>
          <a className="header-link" href="#hh-profile">
            Qumata Profile
          </a>
          <a className="header-link" href="#your-personal-data">
            Your personal data
          </a>
          <a className="header-link" href="#wellness-score">
            Wellness Score
          </a>
          <a className="header-link" href="#third-party-products-and-links">
            Third party products and links
          </a>
          <a className="header-link" href="#intellectual-property-rights">
            Intellectual property rights
          </a>
          <a className="header-link" href="#use-of-the-score-app-and-services">
            Use of the Score App and Services
          </a>
          <a className="header-link" href="#closing-your-hh-profile">
            Closing your Qumata Profile
          </a>
          <a className="header-link" href="#important-disclaimers">
            Important disclaimers
          </a>
          <a className="header-link" href="#limitation-of-liability">
            Limitation of liability
          </a>
          <a className="header-link" href="#making-complaints">
            Making complaints
          </a>
          <a className="header-link" href="#other-important-terms">
            Other important terms
          </a>

          <div className="subheader" id="introduction">
            1. Introduction
          </div>
          <div className="text">
            1.1 The Qumata score mobile software application (the Score App) or
            the website equivalent [{" "}
            <a
              href="https://oneclick.qumata.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              oneclick.qumata.com
            </a>{" "}
            ] (the Website) are provided to you by Qumata Group Ltd, a company
            registered in England and Wales with company registration number
            10964467 (Qumata, our, we or us). Our registered office is at 14
            Dufferin street, EC1Y 8PD London, England. You can contact us by
            emailing us at{" "}
            <a href="mailto:support@qumata.com" target="_top">
              support@qumata.com
            </a>
            .
          </div>
          <div className="text">
            1.2 Please read these end user terms of use (Terms of Use) carefully
            as they set out the basis on which you (also referred to as the
            user) are allowed to use the services, which are offered by us via
            the Score App and the Website, as more particularly described in
            section 4 below (the Services).
          </div>
          <div className="text">
            1.3 By clicking “I agree” to the Terms of Use when using the Score
            App and/or continuing to use any part of the Score App, Services or
            Website, you confirm your acceptance of these Terms of Use (which
            also includes our Privacy Policy and any other terms that we may
            make available from time to time). If you have any questions about
            these Terms of Use, please contact us at{" "}
            <a href="mailto:support@qumata.com" target="_top">
              support@qumata.com
            </a>
            .
          </div>
          <div className="text">
            1.4 Your particular attention is drawn to the following provisions
            of these Terms of Use:
          </div>
          <div className="text sub">
            (a) Section 7.2 – the information provided on the Score App,
            Services and/or Website does not constitute health or medical
            advice.
          </div>
          <div className="text sub">
            (b) Section 7.4 – any advice or information provided via the Score
            App, Services and/or Website has not been evaluated by medical
            professionals or medical bodies, and is not intended to diagnose,
            treat, or cure any illness, condition or other physical or mental
            health issue. It is not intended to be, and should not be used in
            the place of, the advice of medical professionals or any information
            provided on any product packaging or label. For advice on medical,
            health and wellbeing issues you should always consult your doctor or
            other qualified healthcare professional.
          </div>
          <div className="text sub">
            (c) Section 7.5 – you must obtain professional or specialist advice
            before taking, or refraining from, any action on the basis of the
            information made available via the Score App, Website and/or
            Services.
          </div>
          <div className="text sub">
            (d) Section 10 sets out important information relating to the use of
            the Score App, Website and Services and your responsibilities.
          </div>
          <div className="text sub">
            (e) Section 11 sets out information on how to close your Qumata
            Profile.
          </div>
          <div className="text sub">
            (f) Section 13 sets out important information relating to limitation
            of liability.
          </div>
          <div className="text sub">
            (g) Section 14 sets out information on making complaints.
          </div>
          <div className="text">
            1.5 We are providing you with the opportunity to access the Score
            App and Website and receive the Services because you are a customer
            or an employee of a Qumata Client (as defined below).
          </div>
          <div className="text">
            1.6 The Score App, Website and Services are not directed at any
            person in any jurisdiction where the publication or availability of
            them are prohibited. You must not access the Score App, Website
            and/or Services from any such jurisdiction. You are responsible for
            all compliance with local laws and regulations which apply to you.
          </div>

          <div className="subheader" id="definitions">
            2. Definitions
          </div>
          <div className="text">
            Capitalised terms used in these Terms of Use shall have the meaning
            given to those terms below.
          </div>
          <div className="text">
            “Agreement” means the agreement between Qumata and a Qumata Client
            pursuant to which Qumata has agreed to provide you with access to
            the Score App, Website and Services.
          </div>
          <div className="text">
            “Collated Data” means all data about you which is collated within
            the Score App and Website for the purposes of providing the
            Services, including data provided by you and data we collect from
            third parties about you, as set out in our Privacy Policy [{" "}
            <a
              href="https://oneclick.qumata.com/data-privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              oneclick.qumata.com/data-privacy-policy
            </a>{" "}
            ]. As set out in the Privacy Policy, this data includes your gender,
            age, weight, pre- existing conditions, walking and running distance,
            cycling distance, heart rate (and related information), blood
            pressure, weight and BMI.
          </div>
          <div className="text">
            “Qumata Client” means the company that has entered into an Agreement
            with Qumata.
          </div>
          <div className="text">
            “Qumata Profile” means your profile with Qumata, containing records
            of the Collated Data the Score App and Website have received and the
            score information which the Score App and Website have provided to
            you.
          </div>
          <div className="text">
            “Privacy Policy” means our privacy policy available via [{" "}
            <a
              href="https://oneclick.qumata.com/data-privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              oneclick.qumata.com/data-privacy-policy
            </a>{" "}
            ].
          </div>
          <div className="text">
            “Score App” shall have the meaning given to the term in section 1.1.
          </div>
          <div className="text">
            “Services” shall have the meaning given to the term in section 1.2.
          </div>
          <div className="text">
            “Terms of Use” shall have the meaning given to the term in section
            1.2.
          </div>
          <div className="text">
            “Website” shall have the meaning given to the term in section 1.1.
          </div>
          <div className="text">
            “Wellness Score” is an evaluation of your risk to be diagnosed for
            one or several medical conditions based on your Qumata Profile.
          </div>

          <div className="subheader" id="about-the-score-app">
            3. About the Score App and Website
          </div>
          <div className="text">
            3.1 The Score App and Website allow you to create a personalised
            Qumata Profile which incorporates your Collated Data.
          </div>
          <div className="text">
            3.2 The Score App and the Website will provide you and the Qumata
            Client with a Wellness Score using your Collated Data.{" "}
          </div>

          <div className="subheader" id="hh-services">
            4. Qumata Services
          </div>
          <div className="text">
            4.1 Qumata provides the following Services:
          </div>
          <div className="text sub">
            (a) making the Score App available for download through various
            online channels (including the Score App Store and Google Play) as
            well on the Website;
          </div>
          <div className="text sub">
            (b) the ability for you to create, maintain and amend your Qumata
            Profile through the Score App and Website;
          </div>
          <div className="text sub">
            (c) synchronisation of the Collated Data with your Qumata Profile to
            update your Qumata Profile;
          </div>
          <div className="text sub">
            (d) access to wellbeing information through the Score App and
            Website; and
          </div>
          <div className="text sub">
            (e) communications via email, SMS or other means as set out in in
            the Privacy Policy.
          </div>
          <div className="text">
            4.2 You must be aged 18 years or over to use the Score App and
            Website and receive the Services.
          </div>
          <div className="text">
            4.3 Sometimes the Score App and Website may request additional
            permissions related to the settings on your mobile or wearable
            device, such as accessing your location or enabling push
            notifications to enable certain features. If you decide not to give
            us such permissions, this may result in you not having access to
            certain features of the Score App, Website and/or Services.
          </div>
          <div className="text">
            4.4 We may, from time to time, extend or amend the functionality of
            the Score App, Website and/or the Services, and offer additional
            functionality or other services to you. Not all of the functionality
            of the Score App, Website and/or the Services will be available at
            launch in June 2019 but we anticipate this functionality will be
            added within six to twelve months of this date. These additional
            functions may include any software maintenance, amendments and/or
            upgrades to existing features. These Terms of Use will apply to any
            additional services, unless we provide you with specific terms and
            conditions when we make the other service functionality available to
            you.
          </div>

          <div className="subheader" id="hh-profile">
            5. Qumata Profile
          </div>
          <div className="text">
            5.1 You will be given the option to create a Qumata Profile once you
            have downloaded the Score App and/or accessed the Website and agreed
            to these Terms of Use.
          </div>
          <div className="text">
            5.2 To create a Qumata Profile you must provide us with your name,
            email address and a username. If you access the service through a
            client providing you the access to your profile via a unique link,
            we will use this link to associate your profile to your userID and
            refer to you only via this userID with our client.
          </div>
          <div className="text">
            5.3 You must also give us permission to sync the Score App with your
            Collated Data. If you do not give us permission to sync the Score
            App with your Collated Data, we will not be able to provide you with
            a Wellness Score.
          </div>

          <div className="subheader" id="your-personal-data">
            6. Your personal data
          </div>
          <div className="text">
            6.1 In order to provide the Score App, Website and Services, we must
            collect, process and store certain data, including some of your
            personal data and the Collated Data.
          </div>
          <div className="text">
            6.2 We are committed to protecting and respecting your privacy. The
            collection, processing and storage of your data shall be carried out
            in accordance with and governed by these Terms of Use and our
            Privacy Policy. Please see our Privacy Policy [{" "}
            <a
              href="https://oneclick.qumata.com/data-privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              oneclick.qumata.com/data-privacy-policy
            </a>{" "}
            ] for more information about the data we collect from you and how we
            use it.{" "}
          </div>

          <div className="subheader" id="wellness-score">
            7. Wellness Score
          </div>
          <div className="text">
            7.1 The aim of the Score App, Website and our Services is to provide
            each user and the respective Qumata Client with the user’s Wellness
            Score.
          </div>
          <div className="text">
            7.2 We aim to tailor this information in relation to each user and
            provide a relevant Wellness Score to them. However, the information
            provided on the Score App, Website and Services does not constitute
            health or medical advice.
          </div>
          <div className="text">
            7.3 You acknowledge and agree that all information and materials
            provided via the Score App, Services and/or Website, whether
            provided by Qumata or third parties, is provided for general
            information purposes only. Any advice or information provided via
            the Score App, Services and/or Website must not be relied upon as
            being correct or appropriate for you.
          </div>
          <div className="text">
            7.4 Any advice or information provided via the Score App, Services
            and/or Website has not been evaluated by medical professionals or
            medical bodies, and is not intended to diagnose, treat, or cure any
            illness, condition or other physical or mental health issue. It is
            not intended to be, and should not be used in the place of, the
            advice of medical professionals or any information provided on any
            product packaging or label. For advice on medical, health and
            wellbeing issues you should always consult your doctor or other
            qualified health care professional.
          </div>
          <div className="text">
            7.5 You must obtain professional or specialist advice before taking,
            or refraining from taking, any action on the basis of the
            information made available via the Score App, Services and/or
            Website.
          </div>

          <div className="subheader" id="third-party-products-and-inks">
            8. Third party products and links
          </div>
          <div className="text">
            8.1 The Score App or Website may provide links to third party
            websites, for example, when referring to a product or information
            that we feel may be of interest to you, or in general.
          </div>
          <div className="text">
            8.2 If you access any third party websites or purchase any products
            or services via a link to a third party website provided through the
            Score App or Website, the terms and conditions and privacy policy of
            that website shall apply to such access and/or purchase. We may
            carry out certain verification checks on third party suppliers, but
            cannot guarantee any specific supplier's information, accreditation,
            or registration. We make no warranty regarding any goods or services
            purchased or obtained from third parties as a result of using the
            Score App, Services or Website. We make no warranty regarding any
            third party devices used to access or provide data to our Score App,
            Website or Services. You should in all cases make your own
            enquiries. In particular, it is your responsibility to satisfy
            yourself that a third party supplier is solvent and has suitably
            qualified and certified personnel (as appropriate).
          </div>
          <div className="text">
            8.3 We will not be a party to any contract made between you and any
            third party supplier and therefore we shall not be liable for any
            loss or damage that results from any dealings between you and any
            such third party supplier, including but not limited to any direct
            or indirect loss of any kind.
          </div>
          <div className="text">
            8.4 We do not warrant the appropriateness or accuracy of any
            information made available to you by a third party website linked
            from the Score App, Website or Services (regardless of source), and
            we accept no responsibility or liability for the appropriateness,
            accuracy or any other aspect of such websites or the information
            made available by them to you; or any loss or damage that may be
            suffered as a result of following any links.
          </div>
          <div className="text">
            8.5 The Score App and the Score Website may integrate with social
            networking platforms and other third party applications (for
            example, Facebook, Instagram, Twitter). Your use of any integrated
            applications will be subject to those third parties' terms and
            conditions and privacy policies.
          </div>

          <div className="subheader" id="intellectual-property-rights">
            9. Intellectual property rights
          </div>
          <div className="text">
            You acknowledge and agree that all present and future copyright and
            other intellectual property rights subsisting in, or used in
            connection with, the Score App, the Website and Services or any part
            of them, including the manner in which the Score App, the Website
            and Services are presented or appear and all information and
            materials relating to them, is our property (or that of our
            licensors), and nothing in these Terms of Use or your use of the
            Score App, the Website or Services shall be taken to transfer any of
            these rights to you.
          </div>

          <div className="subheader" id="use-of-the-score-app-and-services">
            10. Use of the Score App, Website and Services
          </div>
          <div className="text">
            10.1 Please note that we do not guarantee that the Score App,
            Website or Services will be compatible with your mobile device.
            However, if you are able to download the Score App onto your mobile
            device, it is likely that your mobile device will be compatible.
          </div>
          <div className="text">
            10.2 We also do not guarantee that the Score App, Website or
            Services will be compatible with your wearable device(s) for the
            purposes of syncing the Collated Data.
          </div>
          <div className="text">
            10.3 Please also note that the use of the Score App, Website and/or
            Services may be impacted by the terms of your agreement with your
            mobile device supplier, manufacturer and/or phone service provider.
            Such use may require the use of data which may incur costs with your
            phone service provider, particularly when using roaming tariffs
            abroad.
          </div>
          <div className="text">
            10.4 We grant you a licence to use our Score App, Website and
            Services that is personal to you only, limited, royalty-free,
            non-exclusive, revocable, non-transferable and without the right to
            sublicense. This licence is granted to you to allow you to
            electronically access and use the Score App and Services for the
            purpose of receiving the benefit of the Score App, Website and
            Services in accordance with these Terms of Use.
          </div>
          <div className="text">10.5 You agree that you shall:</div>
          <div className="text sub">
            (a) provide true, accurate, current and complete information about
            yourself when you create your Qumata Profile and maintain and
            promptly update this data to keep it accurate, current and complete;
          </div>
          <div className="text sub">
            (b) not allow anyone else to make use of your Qumata Profile;
          </div>
          <div className="text sub">
            (c) not undertake any activity which may harm our reputation or
            bring us into disrepute;
          </div>
          <div className="text sub">
            (d) not use the Score App, Website or Services for any fraudulent
            purpose or to send, upload or post any content which is abusive,
            defamatory, indecent, menacing, obscene or breaches the rights
            (including intellectual property rights) of any third party;
          </div>
          <div className="text sub">
            (e) not use the Score App, Website or Services to impersonate any
            person or entity, falsely state or otherwise misrepresent your
            affiliation with any person or entity, or express or imply that we
            endorse any statement you make;
          </div>
          <div className="text sub">
            (f) not introduce, transmit or otherwise make available on or via
            the Score App, Website or Services any virus, worm, Trojan horse,
            spyware, or other computer code, file, or programme that is harmful
            or invasive or is intended to damage or hijack the operation of, or
            to monitor the use of, any hardware, software, or equipment;
          </div>
          <div className="text sub">
            (g) not restrict or inhibit any person from using the Score App,
            Website or Services (including hacking or defacing any portion of
            the servers or networks used to host the Score App, Website or
            Services);
          </div>
          <div className="text sub">
            (h) not replicate, modify, adapt, translate, reverse engineer, copy,
            decompile, or disassemble any portion of the Score App, Website or
            Services;
          </div>
          <div className="text sub">
            (i) not remove any copyright, trade mark, or other proprietary
            rights notice from any portion of the Score App, Website or
            Services; and
          </div>
          <div className="text sub">
            (j) not cause the Score App, Website or Services to be interrupted,
            damaged, rendered less efficient or such that the effectiveness or
            functionality of the Score App, Website or Services is in any way
            impaired.
          </div>

          <div className="subheader" id="closing-your-hh-profile">
            11. Closing your Qumata Profile
          </div>
          <div className="text">
            11.1 You can uninstall the Score App at any time, using the
            functionality within the Score App or your mobile device. Please
            note though that uninstalling the Score App will not automatically
            result in the closure of your Qumata Profile. To close your Qumata
            Profile, please send an email to
            <a href="mailto:support@qumata.com" target="_top">
              support@qumata.com
            </a>
            .
          </div>
          <div className="text">
            11.2 We reserve the right to terminate or restrict your access to
            the Score App, Website and/or Services at any time without notice
            for any reason whatsoever. Where possible, we will endeavour to
            provide you with notice of any such termination or restriction of
            access.
          </div>
          <div className="text">
            11.3 If the Agreement is terminated or if your Wellness Score has
            been successfully provided to the Client, your access to the Score
            App, Website and Services will cease.
          </div>

          <div className="subheader" id="important-disclaimers">
            12. Important disclaimers
          </div>
          <div className="text">
            12.1 While we will aim to maintain an uninterrupted service through
            the Score App, Website and Services, they are provided on an “as is”
            basis and we do not promise that your access to the Score App,
            Website or Services will be delivered uninterrupted, securely, in a
            timely manner or error-free, or that the Score App, Website or
            Services will be free from viruses or other harmful properties. We
            recommend that you always use up to date firewalls and anti-virus
            software to protect your equipment and data.
          </div>
          <div className="text">
            12.2 From time to time, we may carry out maintenance of the Score
            App, Website or Services which may result in certain parts of the
            Score App, Website or Services becoming unavailable.
          </div>
          <div className="text">
            12.3 While we try to make sure that the Score App, Website and
            Services are secure, you should note that transmission of
            information via the Internet may not be absolutely secure and could
            be subject to interception by third parties.
          </div>
          <div className="text">
            12.4 We reserve the right to modify or discontinue temporarily or
            permanently all or part of the Score App, Website and/or Services,
            with or without notice, without liability for any modification or
            discontinuance.
          </div>

          <div className="subheader" id="limitation-of-liability">
            13. Limitation of liability
          </div>
          <div className="text">
            13.1 Notwithstanding any other provision, nothing in these Terms of
            Use shall exclude or limit our liability for death or personal
            injury caused by our negligence, fraud or fraudulent
            misrepresentation, or any other liability that cannot lawfully be
            excluded or limited.
          </div>
          <div className="text">
            13.2 If you are dissatisfied with the Score App, the Website, the
            Services or any of these Terms of Use, your sole remedy under these
            Terms of Use shall be to discontinue use of the Score App, the
            Website and Services.
          </div>
          <div className="text">
            13.3 Other than as set out in this section 13, and notwithstanding
            any other provision of these Terms of Use, we shall not be liable in
            contract, tort (including negligence), statutory duty,
            misrepresentation or otherwise, for any loss or damage whatsoever
            arising from or in any way connected with these Terms of Use
            (including our provision of access to and your use of the Score App,
            the Website and/or the Services), including any liability for:
          </div>
          <div className="text sub">
            (a) any of the following:
            <div className="text sub">(i) loss of profits;</div>
            <div className="text sub">(ii) loss of sale or business;</div>
            <div className="text sub">(iii) business interruption;</div>
            <div className="text sub">
              (iv) loss of agreements or contracts;
            </div>
            <div className="text sub">(v) loss of anticipated savings; or</div>
            <div className="text sub">(vi) loss of or damage to goodwill,</div>
            <div className="text sub">
              (vii) whether the same are suffered directly or indirectly; or
            </div>
          </div>
          <div className="text sub">
            (b) any indirect or consequential loss or damage whatsoever.
          </div>
          <div className="text">
            13.4 All conditions, warranties and obligations which may be implied
            or incorporated into these Terms of Use by statute, common law, or
            otherwise and any liabilities arising from them are hereby expressly
            excluded to the extent permitted by law.
          </div>
          <div className="text">
            13.5 Some jurisdictions do not allow the exclusion of certain
            warranties or the limitation or exclusion of liability for indirect
            losses. Accordingly, some of the above limitations of liability may
            not apply to you.
          </div>
          <div className="text">
            13.5 To the extent that any limitation or exclusion of liability in
            these Terms of Use proves ineffective, our maximum aggregate
            liability under or in connection with these Terms of Use (including
            your use of the Score App, Website and Services) whether in
            contract, tort (including negligence), statutory duty,
            misrepresentation or otherwise, shall in all circumstances be
            limited to £100 in aggregate.
          </div>

          <div className="subheader" id="making-complaints">
            14. Making complaints
          </div>
          <div className="text">
            If you have any complaint about the Score App, the Website or any of
            the Services we provide, you should contact us at{" "}
            <a href="mailto:support@qumata.com" target="_top">
              support@qumata.com
            </a>{" "}
            and we will try and resolve it as soon as possible.
          </div>

          <div className="subheader" id="other-important-terms">
            15. Other important terms
          </div>
          <div className="text">
            15.1 We may vary these Terms of Use from time to time. You are
            advised to regularly check these Terms of Use for any such variation
            and note the date set out above on which these Terms of Use were
            last updated. The amended Terms of Use will take effect on the date
            on which we publish any variation through the App. If you do not
            agree with the amended Terms of Use then you have the right to stop
            using the Score App, Website and Services, and should do so
            immediately. Your continued use of the Score App, Website and/or
            Services after the date the changes have been posted will constitute
            acceptance of the amended Terms of Use.
          </div>
          <div className="text">
            15.2 Section headings are inserted for convenience only and shall
            not affect the interpretation of these Terms of Use.
          </div>
          <div className="text">
            15.3 Each paragraph of these Terms of Use shall be construed
            separately and independently of the other paragraphs.
          </div>
          <div className="text">
            15.4 No waiver shall be effective unless in writing, and no waiver
            shall constitute a continuing waiver so as to prevent us from acting
            upon any continuing or subsequent breach or default.
          </div>
          <div className="text">
            15.5 Our failure to exercise or enforce any right or provision of
            these Terms of Use shall not constitute a waiver of such right or
            provision.
          </div>
          <div className="text">
            15.6 If any provision of these Terms of Use shall be interpreted by
            any court of competent jurisdiction to be unenforceable or invalid,
            that provision shall be limited or eliminated to the minimum extent
            necessary so that these Terms of Use shall otherwise remain in full
            force and effect and remain enforceable between you and us.
          </div>
          <div className="text">
            15.7 We are an independent service provider for all purposes when
            carrying out our obligations under these Terms of Use. Nothing in
            these Terms of Use is intended to, or shall be deemed to, establish
            any other kind of legal relationship including partnership, agency,
            trustee, fiduciary duty, joint venture or any other form of legal
            association between us.
          </div>
          <div className="text">
            15.8 Notwithstanding any other provision in these Terms of Use, a
            person who is not a party to these Terms of Use has no right under
            the Contracts (Rights of Third Parties) Act 1999 to rely upon or
            enforce these Terms of Use.
          </div>
          <div className="text">
            15.9 These Terms of Use constitute the entire agreement as to its
            subject matter and supersede and extinguish all previous
            communications, representations (other than fraudulent
            misrepresentations) and arrangements, whether written or oral.
          </div>
          <div className="text">
            15.10 These Terms of Use and any dispute or claim arising out of or
            in connection with them shall be governed by English law. You and we
            both agree that the courts of England and Wales shall have
            non-exclusive jurisdiction.
          </div>
          <div className="text">
            15.11 Nothing in these Terms of Use seeks to limit any mandatory
            rights that you may have as a consumer in your own jurisdiction or
            seek to prevent you from raising any claim in any court which you
            are entitled due to your status as a consumer.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TermsAndConds;
