import qs from "qs";
import React from "react";
import { useNavigate } from "react-router";

import DeviceHeader from "components/DeviceHeader/DeviceHeader";
import { useAlert, UploadingDataError } from "modules/context/AlertProvider";
import { AccessStatus, DeviceId } from "modules/constants";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";

import classes from "./DeviceDataUploading.module.scss";
import Bugsnag from "@bugsnag/js";

import {
  useCollectionStatusQuery,
  CollectionStatusProviderResponse,
} from "modules/utils/hooks";

export const THRESHOLD_REFETCH_COUNT = 4;
export const FIFTEEN_SECONDS = 15000;

const DeviceDataUploading: React.FunctionComponent<{
  deviceId: DeviceId;
}> = ({ deviceId }) => {
  const refetchCount = React.useRef<number>(0);
  const { show, close } = useAlert();
  const navigate = useNavigate();

  const query = window.location.search?.replace("?", "");
  const { status } = qs.parse(query);

  const { data, isFetching } =
    useCollectionStatusQuery<CollectionStatusProviderResponse>(deviceId, {
      refetchInterval: FIFTEEN_SECONDS,
    });

  const mapIdToUrl = {
    [DeviceId.fitbit]: RoutesPaths.connectFitbit,
    [DeviceId.garmin]: RoutesPaths.connectGarmin,
    [DeviceId.google]: RoutesPaths.connectGoogleFit,
    [DeviceId.underarmour]: RoutesPaths.connectUnderarmour,
  };

  const url = mapIdToUrl[deviceId];

  React.useEffect(() => {
    if (isFetching) return;

    try {
      if (status.toString().replace("#_=_", "") !== "success") {
        navigate(url);
      }

      const count = refetchCount?.current ?? 0;
      const hasEnoughData = data && data.has_enough_data;
      const isThresholdReached =
        count >= THRESHOLD_REFETCH_COUNT || hasEnoughData;

      if (isThresholdReached || hasEnoughData) {
        navigate(url);
      }

      refetchCount.current = count + 1;
    } catch (error) {
      Bugsnag.notify(error);

      show(
        "Can not upload your data!",
        <UploadingDataError
          onClose={() => {
            close();
            navigate(url);
          }}
        />,
      );
    }
  }, [close, data, isFetching, navigate, show, status, url]);

  if (!deviceId) {
    return null;
  }

  return (
    <Layout>
      <DeviceHeader device={deviceId} status={AccessStatus.loading} />
      <div className={classes.content}>
        We are connecting your digital device.
      </div>
      <div className={classes.content}>This might take one minute.</div>
    </Layout>
  );
};

export default DeviceDataUploading;
