import React from "react";
import { RoutesPaths } from "modules/routes";
import { useNavigate } from "react-router-dom";

import AboutDataCollection from "components/Modals/AboutDataCollection";
import { useCollectionStatusQuery } from "modules/utils/hooks";
import RevokeConsent from "components/Modals/RevokeConsent";
import ContactUs from "components/Modals/ContactUs";
import Layout from "components/Layout/Layout";
import { devices } from "modules/devices";
import Button from "components/Button";
import DeviceItem from "./DeviceItem";
import "./styles.css";

const ChooseDevices: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const { data, refetch, isFetching, isLoading } = useCollectionStatusQuery();

  const isConnected =
    data && Object.values(data).some((i) => i.is_receiving_data);
  const hasEnoughData =
    data && Object.values(data).some((i) => i.has_enough_data);

  const [showContactUs, toggleContactUs] = React.useState(false);
  const [showDataConnectionInfo, toggleDataConnectionInfo] =
    React.useState(false);

  const [showRevokeConsent, toggleRevokeConsentModal] = React.useState(false);
  const [revokeConsentDevice, setRevokeConsentDevice] = React.useState("");

  const openRevokeConsentModal = (device) => {
    setRevokeConsentDevice(device);
    toggleRevokeConsentModal(true);
  };

  const handleNext = (route) => navigate(route);

  return (
    <Layout progressBarPercent={50}>
      <div className="center-container">
        <div style={{ maxWidth: "450px" }} className="connect-device">
          <div className="choose-device-header">
            Connect your digital device(s)
          </div>

          <div className={`connect-device-sub-title`}>
            Connect your data source(s) so we can gather 14 days of activity
            data for your health assessment.
          </div>

          <React.Fragment>
            <div className="checkboxes-block">
              {devices.map((device, index) => {
                const status = data?.find((i) => i.name === device.name);

                return (
                  <DeviceItem
                    key={
                      (status?.connected ? "connected" : "notConnected") + index
                    }
                    device={device}
                    isLoading={isLoading || isFetching}
                    handleNext={handleNext}
                    openRevokeModal={openRevokeConsentModal}
                    status={status}
                  />
                );
              })}
            </div>

            <div
              onClick={() => toggleDataConnectionInfo(true)}
              className="find-out-more"
            >
              Find out more
            </div>

            <div className="buttons-wrapper">
              <Button
                onClick={() => navigate(RoutesPaths.profile)}
                type="rounded"
                icon="arrowRight"
                pending={isLoading || isFetching}
                disabled={
                  isLoading || isFetching || (!hasEnoughData && !isConnected)
                }
              >
                Continue
              </Button>
            </div>
          </React.Fragment>
        </div>

        {showDataConnectionInfo && (
          <AboutDataCollection
            onClose={() => toggleDataConnectionInfo(false)}
          />
        )}

        {showRevokeConsent && (
          <RevokeConsent
            device={revokeConsentDevice}
            onClose={() => toggleRevokeConsentModal(false)}
            callback={refetch}
          />
        )}
        {showContactUs && <ContactUs onClose={() => toggleContactUs(false)} />}
      </div>
    </Layout>
  );
};

export default ChooseDevices;
